import React from 'react'


const Quote = () => {
    return (
        <>
            <div className='m-1 p-2'>
                <div className='row'>
                    <div className="col-12 d-flex justify-content-center">
                        <div className="col-md-8">

                            <blockquote>
                                Research is the cornerstone of progress, fueling the engine of human advancement and shaping a brighter future for all.
                                <span>Stephen Hawking</span>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Quote;