import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card';
import { Button } from '@mui/material';
import Divider from '@mui/material/Divider';

import { useNavigate, Outlet } from 'react-router-dom';

const Archive = (props) => {
    const navitate = useNavigate();

    const issues = () => {
        console.log(props.issues);
    }

    const goto=(data)=>{
        window.open(
            "/Articles/"+data, "_blank");
    }

    return (
        <>

            {
                props.volume.map((el) => (

                    <Card>
                        <div className='row pb-4 pt-1'>
                            <div className='col-md-12'>
                                <div className='text-dark left-line w-100 p-1 bg-light' disabled>
                                    {el.year}
                                </div>
                            </div>
                            <Divider />

                            {
                                props.issues.map((issue) => (
                                    <>
                                        {el.name === issue.volume &&
                                            <div className='col-6 col-md-4 p-1 text-center'>
                                                <Button variant="outlined" 
                                                onClick={() => goto(issue.issue_id)} 
                                                // onClick={() => navitate(`/Articles/${issue.issue_id}`)} 
                                                className='m-1' size="small">
                                                    {el.name}, {issue.issues}
                                                </Button>
                                            </div>
                                        }
                                    </>
                                ))
                            }

                        </div>

                        <Divider color={'success'} />

                        {/* <div className='row py-3'>
                                <div className='col-md-12'>
                                    <Button variant="outlined" className='text-dark' disabled>
                                        Volume 1 (2023)
                                    </Button>
                                </div>
                                <Divider />
                                <div className='col-md-12 m-2'>
                                    <Button variant="outlined" className='m-1' size="small">
                                        Issue 1
                                    </Button>
                                    <Button variant="outlined" className='m-1' size="small">
                                        Issue 1
                                    </Button>
                                </div>
                            </div>
                            <Divider color={'success'} />
                            <div className='row py-3'>
                                <div className='col-md-12'>
                                    <Button variant="outlined" className='text-dark' disabled>
                                        Volume 1 (2023)
                                    </Button>
                                </div>
                                <Divider />
                                <div className='col-md-12 m-2'>
                                    <Button variant="outlined" className='m-1' size="small">
                                        Issue 1
                                    </Button>
                                    <Button variant="outlined" className='m-1' size="small">
                                        Issue 1
                                    </Button>
                                </div>
                            </div>
                            <Divider color={'success'} /> */}




                    </Card>

                ))
            }






        </>
    )
}

export default Archive