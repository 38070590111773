import React from 'react'
import RightSide from '../components/common/rightSide'
import JoinReviewer from '../components/other/joinReviewer'

const JoinReviewerPage=()=>{
    return (
        <>
          <div className='container-fluid mt-2'>
                <div className='row'>
                {/* <div className='col-md-4'>
                        <RightSide />
                    </div> */}
                    <div className='col-md-12'>
                        <JoinReviewer />
                    </div>
                    
                </div>
            </div>

        </>
    )
}

export default JoinReviewerPage