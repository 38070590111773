import React from 'react'

import Card from '@mui/material/Card';
import { Divider } from '@mui/material';

const PublicationEthics = () => {
    return (
        <>
            <Card>
                <div className="row">
                    <div className="col-12 p-3 text-center">
                        <h3>PUBLICATION ETHICS POLICY OF ISSRA PUBLISHERS</h3>
                    </div>
                </div>
            </Card>
            <Divider />

            <Card>
                <div className="row">


                    <div className="col-md-12 p-4 px-5">
                        <div>
                            <strong>

                                INTRODUCTION
                            </strong>
                        </div>
                        <p>

                            ISSRA Publishers is committed to maintaining the highest ethical standards in all aspects of the publication process. This Publication Ethics Policy outlines the principles and guidelines that govern the behavior and responsibilities of authors, reviewers, and editors involved in the publication of scholarly works.
                        </p>

                        <div>
                            <strong>

                                PUBLICATION ETHICS
                            </strong>
                        </div>

                        <div>
                            <strong>

                                Authorship and Acknowledgment
                            </strong>
                        </div>
                        <p>

                            All individuals who have made significant contributions to the research should be listed as authors. Any other individuals or organizations that have contributed to the research should be acknowledged appropriately.
                        </p>


                        <div>
                            <strong>

                                Multiple Submissions and Redundant Publication
                            </strong>
                        </div>
                        <p>

                            Authors should not submit the same research to multiple publications simultaneously. Redundant publication, also known as duplicate publication or self-plagiarism, should be avoided.
                        </p>


                        <div>
                            <strong>
                                Peer Review
                            </strong>
                        </div>
                        <p>

                            ISSRA Publishers utilizes a rigorous peer-review process to ensure the quality and validity of the research we publish. Peer reviewers are expected to maintain confidentiality, provide unbiased assessments, and declare any conflicts of interest.
                        </p>

                        <div>
                            <strong>

                                Corrections and Retractions
                            </strong>
                        </div>
                        <p>

                            ISSRA Publishers will investigate and address any concerns or allegations of research misconduct promptly. If errors or inaccuracies are identified after publication, appropriate corrections or retractions will be issued.
                        </p>


                        <div>
                            <strong>

                                RESPONSIBILITIES OF AUTHORS
                            </strong>
                        </div>

                        <div>
                            <strong>

                                Originality and Plagiarism
                            </strong>
                        </div>
                        <p>

                            Authors must ensure that their work is original and has not been previously published or submitted elsewhere. Plagiarism in any form, including the use of someone else's work without appropriate acknowledgment or permission, is strictly prohibited. Authors should properly cite and reference all sources used in their work.
                        </p>


                        <div>
                            <strong>

                                Data Integrity
                            </strong>
                        </div>
                        <p>

                            Authors must ensure the accuracy and integrity of the data presented in their work. They should provide detailed information and methodologies to enable the replication of their experiments or research. Falsification, fabrication, or manipulation of data is considered a serious breach of ethical standards.
                        </p>


                        <div>
                            <strong>

                                Authorship and Acknowledgments
                            </strong>
                        </div>
                        <p>
                            
                            All individuals who have made significant contributions to the research should be listed as authors. It is the responsibility of the corresponding author to ensure that all co-authors have reviewed and approved the final version of the manuscript and agree to its submission for publication. Any conflicts of interest or financial support related to the work should be disclosed. All contributors who do not meet the criteria for authorship should be acknowledged in the acknowledgment section.
                        </p>

                        <div>
                            <strong>

                                Multiple Submissions and Redundant Publication
                            </strong>
                        </div>

                        <p>

                            Authors must not submit the same manuscript to multiple journals simultaneously. It is considered unethical to publish redundant or overlapping manuscripts, as this can lead to duplicate publication or self-plagiarism.
                        </p>


                        <div>
                            <strong>

                                RESPONSIBILITIES OF REVIEWERS
                            </strong>
                        </div>

                        <div>
                            <strong>

                                Confidentiality
                            </strong>
                        </div>

                        <p>

                            Reviewers must treat all manuscripts they receive for review as confidential documents. They should not share, discuss, or disclose any information regarding the manuscript with any unauthorized individuals. Reviewers should decline to review any manuscript if there is a conflict of interest or if they feel inadequately qualified to assess the work.
                        </p>


                        <div>
                            <strong>

                                Objectivity and Constructive Feedback
                            </strong>
                        </div>
                        <p>

                            Reviewers should conduct the review process objectively and provide constructive feedback to the authors. Personal criticism of the authors is discouraged. Reviewers should promptly notify the editor if they suspect any ethical misconduct, plagiarism, or fraudulent data in the manuscript.
                        </p>

                        <div>
                            <strong>

                                RESPONSIBILITIES OF EDITORS
                            </strong>
                        </div>
                        <div>
                            <strong>

                                Editorial Decisions
                            </strong>
                        </div>
                        <p>

                            Editors have the responsibility to evaluate the submitted manuscripts based on their originality, significance, methodology, and adherence to ethical standards. They should ensure a fair and unbiased review process, free from any discrimination, personal bias, or conflict of interest.
                        </p>

                        <div>
                            <strong>

                                Confidentiality
                            </strong>
                        </div>
                        <p>

                            Editors and editorial staff should treat all submitted manuscripts as confidential and should not disclose any information about the manuscripts or their authors unless required for the peer review process. They should protect the anonymity of reviewers and authors throughout the review process.
                        </p>


                        <div>
                            <strong>

                                Publication Ethics and Misconduct
                            </strong>
                        </div>
                        <p>

                            Editors should actively investigate any allegations of ethical misconduct raised in submitted manuscripts or during the review process. They should take appropriate actions in response to proven cases of misconduct, which may include retracting or correcting the published work. Editors should also provide a clear mechanism for authors to appeal editorial decisions.
                        </p>

                        <div>
                            <strong>

                                COMPLIANCE WITH ETHICAL GUIDELINES
                            </strong>
                        </div>

                        <div>
                            <strong>

                                Authors' Responsibility
                            </strong>
                        </div>
                        <p>

                            Authors are responsible for ensuring that their research adheres to relevant ethical guidelines, regulations, and legal requirements.
                        </p>

                        <div>
                            <strong>

                                Education and Awareness
                            </strong>
                        </div>
                        <p>

                            ISSRA Publishers will provide resources and support to authors, reviewers, and editors to promote understanding and compliance with ethical guidelines and best practices.
                        </p>

                        <div>
                            <strong>

                                Continuous Improvement
                            </strong>
                        </div>
                        <p>

                            ISSRA Publishers is committed to continuously reviewing and improving our research ethics policies and procedures to uphold the highest standards of ethical conduct.
                        </p>

                        <div>
                            <strong>

                                PUBLICATION ETHICS COMMITTEE
                            </strong>
                        </div>

                        <p>

                            ISSRA Publishers will establish a Publication Ethics Committee comprising experts in the field to handle cases related to publication ethics and misconduct. The committee will investigate allegations of ethical violations, ensure the fairness of the process, and recommend appropriate actions to uphold the ethical standards of the publisher.

                        </p>

                        <div>
                            <strong>

                                DISCLOSURE AND CORRECTIONS
                            </strong>
                        </div>
                        <p>

                            ISSRA Publishers encourages authors to promptly notify the editor if they discover any significant errors or inaccuracies in their published work. Corrections, retractions, or expressions of concern will be considered and implemented as appropriate to maintain the integrity of the scholarly record.
                        </p>


                        {/* <p>
                            <strong>

                                CONCLUSION
                            </strong>
                        </p>
                        <p>

                            ISSRA Publishers is dedicated to promoting integrity, transparency, and high ethical standards in academic publishing. All stakeholders, including authors, reviewers, and editors, are expected to adhere to this Publication Ethics Policy. Any violations will be dealt with seriously and may result in appropriate disciplinary actions.
                        </p> */}


                    </div>
                </div>
            </Card>
        </>
    )
}

export default PublicationEthics;