import React from 'react'

import Card from '@mui/material/Card';
import { Divider } from '@mui/material';

const RefundPolicy = () => {
    return (
        <>
            <Card>
                <div className="row">
                    <div className="col-12 p-4">
                        <h3 className='text-center'>CANCELLATION/REFUND POLICY</h3>
                    </div>
                </div>
            </Card>
            <Divider />

            <Card>
                <div className="row">
                    <div className="col-md-12 p-4 px-5">
                        <p>
                            ISSRA Publisher is committed to providing a fair and transparent cancellation/refund policy for article processing charges (APCs) paid by authors. This policy outlines the guidelines and procedures for cancellation and refund requests.
                        </p>

                        <div>
                            <strong>

                                WITHDRAWAL OF MANUSCRIPT
                            </strong>
                        </div>
                        <p>

                            If an author decides to withdraw their manuscript from publication the author is free to withdraw an article at no charge – as long as it is withdrawn within 7 days of its initial submission. The withdrawal request should be submitted in writing to the publisher's designated contact email.
                        </p>

                        <div>
                            <strong>

                                REFUND ELIGIBILITY
                            </strong>
                        </div>

                        <div>
                            <strong>

                                Non-Review Stage
                            </strong>
                        </div>
                        <p>

                            If an article has not entered the review stage or undergone substantial editorial processing, the author may be eligible for a full refund of the APCs.
                        </p>

                        <div>
                            <strong>

                                Acceptance and Publication
                            </strong>
                        </div>

                        <p>

                            Once an article has been accepted for publication and/or published, the APCs become non-refundable. This is due to the costs and resources involved in the editorial and production processes.
                        </p>

                        <div>
                            <strong>

                                Copyright Infringement Claims
                            </strong>
                        </div>
                        <p>

                            If the paper is removed due to copyright infringement claims, no refunds or cancellation of APCs will be provided.
                        </p>


                        <div>
                            <strong>

                                Wrongly Deposited or Extra Deposit
                            </strong>
                        </div>
                        <p>

                            If an author wrongly deposits money or makes an extra deposit, the amount will be returned within 3 weeks if the author informs us within 2 days of the date of deposit.
                        </p>

                        <div>
                            <strong>

                                NON-REFUNDABLE POLICY
                            </strong>
                        </div>

                        <p>

                            If an author withdraws the paper from consideration or declines to revise and resubmit after two working days, refunds or cancellation of debts will strictly adhere to the following policy:
                            <br />
                            1.	Once the two working days period has passed, the APCs become non-refundable. The author will not be eligible for a refund of the paid APCs in such cases.
                            <br />
                            2.	By submitting the paper to ISSRA Publisher and paying the APCs, the author acknowledges a commitment to proceed with the publication process. The payment signifies the author's understanding that the APCs are necessary to cover the costs associated with editorial handling, peer review, production, and other publication-related expenses.
                            <br />
                            3.	ISSRA Publisher invests time, effort, and resources in processing submitted papers, including assigning reviewers, coordinating editorial activities, and managing the publication workflow. Withdrawal or refusal to revise and resubmit after two working days can disrupt the publication process and result in the loss of time and resources invested by the publisher.
                            <br />
                            4.	The paid APCs contribute to the provision of various services and activities involved in the publication process, regardless of the final outcome of the paper. These services include peer review, editorial handling, copy editing, typesetting, and other production-related tasks. As such, the APCs are considered payment for the services rendered up to that point, irrespective of the paper's acceptance or publication status.
                        </p>


                        <div>
                            <strong>

                                REFUND PROCESSING
                            </strong>
                        </div>

                        <div>
                            <strong>

                                Refund Evaluation
                            </strong>
                        </div>
                        <p>

                            Each refund request will be evaluated on a case-by-case basis. ISSRA Publisher reserves the right to determine eligibility for refunds based on the specific circumstances and policies applicable to the request.
                        </p>

                        <div>
                            <strong>

                                Refund Amount
                            </strong>
                        </div>

                        <p>

                            If a refund is approved, the author will generally be eligible for a full refund of the paid APCs.
                        </p>


                        <div>
                            <strong>

                                Refund Method
                            </strong>
                        </div>

                        <p>

                            Refunds will typically be issued using the same payment method used for the original transaction. If a refund cannot be processed through the original payment method, ISSRA Publisher may offer alternative refund options, such as bank transfers or other mutually agreed arrangements.
                        </p>

                        <div>
                            <strong>

                                NON-REFUNDABLE CHARGES
                            </strong>
                        </div>


                        <div>
                            <strong>

                                Administrative Fees
                            </strong>
                        </div>

                        <p>

                            Certain administrative fees or charges associated with the publication process may be non-refundable. These fees cover administrative tasks, such as manuscript screening, handling, or processing, and are necessary to cover the costs incurred by ISSRA Publisher.
                        </p>

                        <div>
                            <strong>

                                UPDATES TO THE CANCELLATION/REFUND POLICY
                            </strong>
                        </div>
                        <p>

                            ISSRA Publisher reserves the right to modify or update this cancellation/refund policy as necessary. Any changes to the policy will be communicated through our website or other appropriate channels. Authors are encouraged to review the policy periodically to stay informed about any revisions.
                        </p>

                        <p>


                            For any questions or concerns regarding the cancellation/refund policy of APCs, please contact our customer support team or refer to the refund information provided on our website.
                        </p>



                    </div>

                </div>
            </Card>
        </>
    )
}

export default RefundPolicy;