import React from 'react'
import Card from '@mui/material/Card';
import { Paper } from '@mui/material';

import { Divider } from '@mui/material';
import { Button } from '@mui/material';
import PinDropIcon from '@mui/icons-material/PinDrop';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import BusinessIcon from '@mui/icons-material/Business';


const ContactUs = () => {
    return (
        <>
            <Card sx={{ height: '100%' }}>
                <Card>
                    <div className='p-3 text-center'>
                        <h3> CONTACT US </h3>
                    </div>
                </Card>
                <Divider />
                <div className="row">

                    <div className='col-md-12 p-4' style={{ height: '100%' }} >
                        <paper sx={{ height: '100%' }}>
                            <div className="row">
                                <div className="col-md-6">
                                    <Paper elevation={3}>
                                        <div className='p-3'>
                                            <div>
                                                <div className="text-center">
                                                    <h4>
                                                        Contact Information
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className='m-3'>
                                                <div>
                                                    <strong>
                                                        <h5>
                                                            <BusinessIcon /> ISSRA Publishers
                                                        </h5>
                                                    </strong>
                                                </div>
                                                <div>
                                                    <span>
                                                        <strong> <PinDropIcon /> Build No- 34, Uttar Nowabil, Ambari, Hojai, Assam, India - 782445</strong>
                                                    </span>
                                                </div>
                                                <div>
                                                    <strong>
                                                        <WhatsAppIcon />  +91 7002576280 / +91 7636929055
                                                    </strong>
                                                </div>
                                                <div>
                                                    <strong>
                                                        <ContactMailIcon /> issrapublisher@gmail.com
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                    </Paper>
                                    <br />
                                    <Paper elevation={3}>
                                        <div className='p-3'>
                                            {/* <div>
                                                <div className="text-center">
                                                    <h4>
                                                        Contact Information
                                                    </h4>
                                                </div>
                                            </div> */}
                                            <div className='m-3'>
                                                <div>
                                                    <strong>
                                                        <h5>
                                                            {/* <BusinessIcon /> */}
                                                              Manuscript Submission Email :
                                                        </h5>
                                                    </strong>
                                                </div>
                                                
                                                <div>
                                                    <strong>
                                                        <ContactMailIcon /> submit.issrapublishers@gmail.com
                                                    </strong>
                                                </div>
                                            </div>
                                        </div>
                                    </Paper>
                                </div>

                                <div className="col-md-6">
                                    <Paper elevation={3}>
                                        <div className='p-3'>
                                            <div>
                                                <div className="text-center">
                                                    <h4>
                                                        Get In Touch
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className="row p-2">
                                                <div className="col-12 p-2">
                                                    <label htmlFor="">Your Full Name</label>
                                                    <input type="text" className='form-control' name="" id="" />
                                                </div>
                                                <div className="col-12 p-2">
                                                    <label htmlFor="">Email</label>
                                                    <input type="text" className='form-control' name="" id="" />
                                                </div>
                                                <div className="col-12 p-2">
                                                    <label htmlFor="">Phone No (Country Code Mandatory)</label>
                                                    <input type="text" className='form-control' name="" id="" />
                                                </div>
                                                <div className="col-12 p-2">
                                                    <label htmlFor="">Your Message</label>
                                                    <input type="text" className='form-control' name="" id="" />
                                                </div>
                                                <div className="col-12 p-2 text-center">
                                                    <Button variant='contained'>Send</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Paper>
                                </div>
                            </div>
                        </paper>

                    </div>
                </div>
            </Card>
            <br />
        </>
    )
}

export default ContactUs