import React from 'react'
import Card from '@mui/material/Card';

import { Divider } from '@mui/material';

const AuthorInstruction = () => {
    return (
        <>
            <Card>
                <div className='p-3 text-center'>
                    <h3> AUTHORS' GUIDELINES </h3>
                </div>
            </Card>
            <Divider />
            <Card>
                <div className="row">

                    <div className='col-md-12 p-4 m-2 px-5'>

                        <p>

                            Thank you for considering ISSRA Publishers as a potential publisher for your work. To ensure a smooth and efficient publishing process, we have prepared the following guidelines to assist you in submitting your manuscript. Please carefully review the information provided below.
                        </p>

                        <div>
                            <strong>
                                MANUSCRIPT PREPARATION
                            </strong>
                        </div>

                        <div>
                            <strong>
                                Language
                            </strong>
                        </div>
                        <p>
                            Papers are accepted in any language.
                        </p>

                        <div>
                            <strong>
                                Page Format
                            </strong>
                        </div>
                        <p>
                            Prepare your manuscript in a standard word processing format (e.g., Microsoft Word) using a clear and legible font (e.g., Times New Roman, Arial) on A4 size with a font size of 12 points. Articles should be within 30 printable pages. Extra pages will be charged.
                        </p>

                        <div>
                            <strong>
                                Page Length
                            </strong>
                        </div>
                        <p>

                            Ensure that your manuscript is concise and adheres to the prescribed length guidelines mentioned in the specific submission call or publisher's requirements.
                        </p>

                        <div>
                            <strong>

                                Structuring
                            </strong>
                        </div>

                        <p>

                            Organize your manuscript into sections such as Abstract, Keywords, Introduction, Experimental section or Methods, Results, Discussion, Conclusion, References and Acknowledgement (if applicable). Use appropriate headings to facilitate readability.
                        </p>

                        <div>
                            <strong>

                                Title page
                            </strong>
                        </div>

                        <p>

                            The title page contains Title of the manuscript, all author names and their corresponding affiliations as well as complete mailing address, telephone and E-mail. The designated corresponding author must be identified by an asterisk.
                        </p>

                        <div>
                            <strong>

                                Abstract
                            </strong>
                        </div>
                        <p>

                            It should not exceed 250 words in a single paragraph and not required sub-headings and should be a brief summary of the work carried out including the objectives of the study, the techniques used and what was accomplished in a concise manner.
                        </p>

                        <div>
                            <strong>

                                Keywords
                            </strong>
                        </div>
                        <p>

                            It should contain up to 3-6 key terms related to the work separated by commas.
                        </p>


                        <div>
                            <strong>

                                Introduction
                            </strong>
                        </div>
                        <p>

                            It should represent the background significance, brief survey of the previous works, purpose, scope and novelty of the research work and should not have subheadings.
                        </p>

                        <div>
                            <strong>

                                Experimental Section/Materials and Methods
                            </strong>
                        </div>
                        <p>

                            Sufficient information in detail regarding the materials and the methods used to carry out the research works (analytical, statistical and experimental procedures) should be mentioned to enable the others to repeat the authors work. Source of chemicals and drugs, animals used, ethical committee permission should be mentioned.
                        </p>

                        <div>
                            <strong>

                                Figures and Tables
                            </strong>
                        </div>
                        <p>

                            Include all figures and tables within the manuscript and ensure they are of high quality, clearly labeled, and referenced in the text.
                        </p>

                        <div>
                            <strong>

                                Results and Discussion
                            </strong>
                        </div>

                        <p>

                            It should contain summary of the research, results, interpretations, speculations and assessment of future research or prospects.
                        </p>

                        <div>
                            <strong>

                                Conclusion
                            </strong>
                        </div>
                        <p>

                            It should include outcome of the work, important findings and your view(s).
                        </p>

                        <div>
                            <strong>

                                Acknowledgements (if any)
                            </strong>
                        </div>

                        <p>

                            It should have the brief information regarding any research grant support or the assistance of colleagues or institutions.
                        </p>


                        <div>
                            <strong>

                                References
                            </strong>
                        </div>

                        <p>

                            These should be arranged at the end of the manuscript in order of their appearance the text. The references should be presented in bracket as et al. in e.g., Single Author (Raju, S. 2018), Multiple ( Raju et al, 2018) in the text. References should follow the APA Citation Style.
                        </p>

                        <div>

                            For Examples:
                        </div>
                        <p>

                            1.	Book: Smith, J. D. (2021). The Power of Habit: Why We Do What We Do in Life and Business. Random House.
                            <br />
                            2.	Journal Article: Johnson, P. W., & Thompson, R. D. (2020). The effects of mindfulness meditation on stress reduction. Journal of Counseling Psychology, 67(2), 112-125.
                            <br />
                            3.	Website: World Health Organization. (2022). Mental health and psychosocial support during the COVID-19 pandemic. Retrieved from https://www.who.int/mental_health/emergencies/covid-19/en/
                            <br />
                            4.	Conference Paper: Davis, L., & Miller, C. (2019). Artificial intelligence and its impact on healthcare: A review of current trends. In Proceedings of the International Conference on Health Informatics (pp. 45-52).
                            <br />
                            5.	Dissertation: Anderson, T. L. (2020). The impact of social media on body image satisfaction among adolescents (Doctoral dissertation). University of California, Los Angeles.
                            <br />
                            6.	Newspaper Article: Roberts, J. (2022, June 10). New study reveals breakthrough in cancer treatment. The New York Times, p. A1.
                            <br />
                            7.	Report: United Nations. (2021). Sustainable Development Goals Report. Retrieved from https://unstats.un.org/sdgs/report/2021/
                            <br />
                            8.	YouTube Video: Khan, S. (2022, September 15). Introduction to calculus [Video]. YouTube. https://www.youtube.com/watch?v=3T9pllLktFI
                            <br />
                        </p>

                        <p>

                            Remember to adjust the formatting and details based on the specific requirements of the APA style guide and the source you are referencing.
                        </p>

                        <p>

                            Please note that these guidelines provide a general overview, and specific requirements may vary depending on the publication type and scope. We recommend reviewing the detailed guidelines provided on our website or contacting ISSRA Publishers directly for further clarification.
                        </p>



                    </div>
                </div>
            </Card>
            <br />
        </>
    )
}

export default AuthorInstruction