import React from 'react'
import RightSide from '../components/common/rightSide';
import ReviwerGuidlines from '../components/other/reviwerGuidlines';



const ReviewerGuidlinesPage = () => {
    return (
        <>
            <div className='container-fluid mt-2'>
                <div className='row'>
                    {/* <div className='col-md-4'>
                        <RightSide />
                    </div> */}
                    <div className='col-md-12'>
                        <ReviwerGuidlines />
                    </div>

                </div>
            </div>
        </>
    )
}

export default ReviewerGuidlinesPage;