import './App.css';
import { useEffect, useState } from 'react';
import PublicInterface from './interface/publicInterface';
import AdminInterface from './interface/adminInterface';

function App() {

  const [user, setUser] =useState('');

  useEffect(()=>{
    if(sessionStorage.getItem('user')){
      setUser('admin')
    }else{
      setUser('public')  
    }

  }, [user]);


  

  return (
    <>
      {user === 'public' &&
        <PublicInterface />
      }
      {user === 'admin' &&
        <AdminInterface />
      }


      {/* <Routes>
        <Route path='/admin' element={<Dashboard />} />
      </Routes>


      <Routes>

        <Route path='/admin' element={<Dashboard />}>
        </Route>

      </Routes> */}

    </>
  );
}

export default App;
