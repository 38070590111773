import React from 'react'
import Card from '@mui/material/Card';
import { Divider } from '@mui/material';

const OpenAccess = () => {
    return (
        <>
            <Card>
                <div className="row">
                    <div className="col-12 p-4">
                        <h3> Open Access Policy</h3>
                    </div>
                </div>
            </Card>
            <Divider />

            <Card>
                <div className="row">
                    <div className="col-12 p-4">
                        <p>
                            All research articles published in  Publisher are fully open access: immediately freely available to read, download and share. Articles are published under the terms of a Creative Commons license which permits use, distribution and reproduction in any medium, provided the original work is properly cited
                        </p>
                        <p>
                            1, the authors and copyright holders are allow to access without any fee globally. incessant right of access to, and a license to copy, distribute, transmit and display the work publicly and to make and distribute derivative works, in any digital medium for any responsible purpose, subject to proper attribution of authorship, as well as the right to make small numbers of printed copies for their personal use
                        </p>
                        <p>
                            2. No special permission is required to reuse all or part of article published by  Publisher. For articles published under an open access Creative Common CC BY license, any part of the article may be reused without permission provided that the original article is clearly cited. Reuse of an article does not imply endorsement by the authors or  Publisher.
                        </p>
                        <p>
                            Open access is a property of individual works, not necessarily journals or publishers
                        </p>
                    </div>
                </div>
            </Card>
        </>
    )
}

export default OpenAccess;