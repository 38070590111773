import React, { useState } from 'react'

import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import SendIcon from '@mui/icons-material/Send';
import LogoHeader from './logoHeader';
import { NavLink, useNavigate } from "react-router-dom";

const Navup = () => {
  const navigate = useNavigate();

  const [expanded, setExpanded] = useState(false);


  return (
    <>
      {/* <div className='bg-secondary p-1 text-white text-end '>
        <div className='mx-3'>
          <a href="" style={{ color: 'white' }}>
            <WhatsAppIcon />
          </a>
          &nbsp;
          <a href="" style={{ color: 'white' }}>
            <FacebookIcon />
          </a>
          &nbsp;
          <a href="" style={{ color: 'white' }}>
            <InstagramIcon />
          </a>
        </div>
      </div> */}


      <LogoHeader />




      <Navbar expanded={expanded} sticky="top" bg="warning" expand="lg" className=" botton-line">
        <Container fluid className=''>
          <Navbar.Brand href="#" className='text-white'>
            {/* <img src='./logo.png' width="300" alt='ISAR'/> */}
            ISSRA Publishers
          </Navbar.Brand>
          <Navbar.Toggle onClick={() => setExpanded(expanded ? false : "expanded")} aria-controls={`offcanvasNavbar-expand-lg`} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
          >
            <Offcanvas.Header closeButton onClick={() => setExpanded(false)}>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                ISAR Publishers
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-center flex-grow-1 pe-3">
                <NavLink onClick={() => setExpanded(false)} className="nav-link scrollt" to="/">Home</NavLink>
                <NavLink onClick={() => setExpanded(false)} className="nav-link scrollt" to="AllJournals">Journals</NavLink>

                <NavDropdown
                  title="For Authors"
                  id={`offcanvasNavbarDropdown-expand-lg`}
                >
                  <NavLink onClick={() => setExpanded(false)} className="nav-link scrollt font-small" to="/author-instruction">
                    Authors Guidlines
                  </NavLink>
                  <NavLink onClick={() => setExpanded(false)} className="nav-link scrollt font-small" to="/joinAuthor">
                    Join Author
                  </NavLink>
                  <NavLink onClick={() => setExpanded(false)} className="nav-link scrollt font-small" to="/AuthorResponsibility">
                    Author Responsibility
                  </NavLink>
                  {/* <NavLink onClick={() => setExpanded(false)} className="nav-link scrollt font-small" to="open-access-policy">
                    Open Access Policy
                  </NavLink>
                  <NavLink onClick={() => setExpanded(false)} className="nav-link scrollt font-small" to="/reviewProcess">
                    Review Process
                  </NavLink> */}
                </NavDropdown>

                <NavDropdown
                  title="For Editors"
                  id={`offcanvasNavbarDropdown-expand-lg`}
                >
                  <NavLink onClick={() => setExpanded(false)} className="nav-link scrollt font-small" to="/EditorGuidlines">
                    Editors Guidlines
                  </NavLink>
                  <NavLink onClick={() => setExpanded(false)} className="nav-link scrollt font-small" to="/JoinEditor">
                    Join Editor
                  </NavLink>
                </NavDropdown>
                <NavDropdown
                  title="For Reviewers"
                  id={`offcanvasNavbarDropdown-expand-lg`}
                >
                  <NavLink onClick={() => setExpanded(false)} className="nav-link scrollt font-small" to="/ReviewerGuidlines">
                    Reviewers Guidlines
                  </NavLink>
                  <NavLink onClick={() => setExpanded(false)} className="nav-link scrollt font-small" to="/JoinReviewer">
                    Join Reviewer
                  </NavLink>
                </NavDropdown>


                <NavLink onClick={() => setExpanded(false)} className="nav-link scrollt" to="aboutUs">About Us</NavLink>
                <NavLink onClick={() => setExpanded(false)} className="nav-link scrollt" to="payment">Payment</NavLink>
                <NavLink onClick={() => setExpanded(false)} className="nav-link scrollt" to="ContactUs">Contact Us</NavLink>


              </Nav>


              {/* <Form className="d-flex">
                <Form.Control
                  type="search"
                  placeholder="Search"
                  className="me-2"
                  aria-label="Search"
                />
                <Button variant="outline-success">Search</Button>
              </Form> */}

              {/* <button className='btn btn-warning btn-sm px-3' onClick={() => navigate('/manuscript')} style={{ borderRadius: '20px' }}>Submit Manuscript <SendIcon /> </button> */}

            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}

export default Navup;

