import React from 'react'
import Card from '@mui/material/Card';
import LockOpenIcon from '@mui/icons-material/LockOpen';

const OpenAccess = () => {
    return (
        <>


            <div className='pt-2'>
                <Card>
                    <div className='bg-primary p-2 text-white botton-line'>
                        Open Access
                    </div>

                    <div className="col-12 input-group p-3 d-flex justify-content-center">
                        <div className='m-2 text-center'>
                            <LockOpenIcon color="secondary" fontSize="large" />
                        </div>

                        <p className='text-justify'>
                            Open access journals are journals whose articles are available and reusable worldwide free of charges like we are not charging fee for any content submission as well as it is available in our website complete free
                        </p>
                    </div>

                </Card>
            </div>





        </>
    )
}

export default OpenAccess;