import React from 'react'

import Card from '@mui/material/Card';
import { Divider } from '@mui/material';

const PrivacyPolicy = () => {
    return (
        <>
            <Card>
                <div className="row">
                    <div className="col-12 p-3 text-center">
                        <h3>PRIVACY POLICY OF ISSRA PUBLISHERS</h3>
                    </div>
                </div>
            </Card>
            <Divider />

            <Card>
                <div className="row">


                    <div className="col-md-12 p-4 px-5">
                        <p>
                            We are committed to protecting the privacy and personal information of our users, customers, and visitors ("you" or "users"). This Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you interact with our website, products, and services (collectively referred to as "Services").
                        </p>

                        <div>
                            <strong>
                                INFORMATION WE COLLECT
                            </strong>
                        </div>

                        <div>
                            <strong>
                                Personal Information
                            </strong>
                        </div>

                        <p>
                            When you use our Services, we may collect personal information such as your name, email address, postal address, phone number, and other information you provide to us or other information related to you.
                        </p>

                        <div>
                            <strong>
                                Log Data
                            </strong>
                        </div>

                        <p>
                            We may automatically collect certain information when you access or use our Services. This information may include your IP address, browser type, device information, operating system, and other usage details.
                        </p>

                        <div>
                            <strong>
                                Cookies and Similar Technologies
                            </strong>
                        </div>
                        <p>
                            We may use cookies, web beacons, and similar technologies to collect information about your interactions with our Services. This information helps us provide and improve our Services, customize your experience, and analyze usage patterns.
                        </p>

                        <div>
                            <strong>
                                USE OF INFORMATION
                            </strong>
                        </div>

                        <div>
                            <strong>
                                Personal Information
                            </strong>
                        </div>

                        <p>

                            Any personal information received by ISSRA Publishers will be used to:
                            <br />

                            •	Administer this website;
                            <br />
                            •	Personalize the website for you;
                            <br />
                            •	Enable your access to and use of the website services;
                            <br />
                            •	Process your manuscript;
                            <br />
                            •	Publish your manuscript;
                            <br />
                            •	Send to you communication about your manuscript;
                            <br />
                            •	Send to you statements and invoices;
                            <br />
                            •	Collect payments from you and;
                            <br />
                            •	Send you marketing communications.
                        </p>

                        <p>

                            Where ISSRA Publishers discloses your personal information to its agents or sub-contractors for above those purposes, the agent or sub-contractor in question will be obligated to use that personal information in accordance with the terms of this privacy statement.
                        </p>

                        <p>
                            In addition to the disclosures reasonably necessary for the purposes identified elsewhere above, ISSRA Publishers may disclose your personal information to the extent that it is required to do so by law, in connection with any legal proceedings or prospective, and in order to establish, exercise or defend its legal rights.
                        </p>

                        <div>
                            <strong>

                                Providing Services
                            </strong>
                        </div>
                        <p>

                            We use the collected information to deliver and improve our Services, fulfill your requests, communicate with you, and provide customer support.
                        </p>

                        <div>
                            <strong>

                                Personalization
                            </strong>
                        </div>
                        <p>

                            We may use your information to personalize your experience, such as recommending relevant content, products, or services based on your interests and preferences.
                        </p>

                        <div>
                            <strong>

                                Analytics and Research
                            </strong>
                        </div>
                        <p>

                            We may analyze the information we collect to understand user trends, preferences, and demographics. This analysis helps us improve our Services and develop new offerings.
                        </p>

                        <div>
                            <strong>

                                Legal Compliance
                            </strong>
                        </div>
                        <p>

                            We may use your information to comply with applicable laws, regulations, or legal processes, and to protect our rights, privacy, safety, or property, and those of our users.
                        </p>

                        <div>
                            <strong>

                                DATA SHARING AND DISCLOSURE
                            </strong>

                        </div>

                        <div>
                            <strong>

                                Service Providers
                            </strong>
                        </div>

                        <p>

                            We may engage trusted third-party service providers to assist us in delivering our Services. These service providers have limited access to your information and are contractually obligated to protect it and use it solely for the purpose of providing the requested services.
                        </p>

                        <div>
                            <strong>

                                Legal Requirements
                            </strong>
                        </div>
                        <p>

                            We may disclose your information if required by law, court order, or government regulation, or if we believe it is necessary to protect our rights, property, or safety, or the rights, property, or safety of others.
                        </p>


                        <div>
                            <strong>

                                Business Transfers
                            </strong>
                        </div>
                        <p>

                            In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred or disclosed as part of the transaction. We will notify you and provide choices regarding your information in such circumstances.
                        </p>


                        <div>
                            <strong>

                                Cross-Border Data Transfers
                            </strong>
                        </div>
                        <p>

                            Information that ISSRA Publishers collects may be stored and processed in and transferred between any of the countries in which ISSRA Publishers operates to enable the use of the information in accordance with this privacy policy.
                        </p>

                        <div>
                            <strong>

                                DATA SECURITY
                            </strong>
                        </div>
                        <p>

                            We implement reasonable security measures to protect the confidentiality, integrity, and availability of your personal information. However, please be aware that no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your information.
                        </p>


                        <div>
                            <strong>

                                YOUR CHOICES AND RIGHTS
                            </strong>
                        </div>

                        <div>
                            <strong>

                                Opt-Out
                            </strong>
                        </div>
                        <p>

                            You may choose not to provide certain information or opt-out of receiving promotional communications from us. However, please note that some features or services may be unavailable as a result.
                        </p>

                        <div>
                            <strong>

                                Access and Correction
                            </strong>
                        </div>

                        <p>

                            You have the right to access, correct, or update your personal information in our records. If you would like to exercise this right, please contact us using the contact details provided at the end of this Privacy Policy.
                        </p>


                        <div>
                            <strong>

                                Data Retention
                            </strong>
                        </div>
                        <p>

                            We retain your personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.
                        </p>

                        <div>
                            <strong>

                                CHILDREN'S PRIVACY
                            </strong>
                        </div>

                        <p>

                            Our Services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children. If you believe we have inadvertently collected information from a child, please contact us immediately, and we will take appropriate steps to remove the information from our records.
                        </p>


                        <div>
                            <strong>

                                CHANGES TO THIS PRIVACY POLICY
                            </strong>
                        </div>

                        <p>

                            We may update this Privacy Policy from time to time to reflect changes in our practices or legal obligations. You should check this page occasionally to ensure you are familiar with any changes. Your continued use of our Services after the effective date of the updated Privacy Policy constitutes your acceptance of the changes.
                        </p>

                        <div>
                            <strong>

                                OTHER WEBSITES
                            </strong>
                        </div>

                        <p>

                            This website contains links to other websites. ISSRA Publishers is not responsible for the privacy policies or practices of any third party.
                        </p>

                        <div>
                            <strong>

                                CONTACT US
                            </strong>
                        </div>
                        <p>

                            If you have any questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at:
                        </p>

                        <p>

                            ISSRA Publishers
                            <br />
                            Address: Building No: 34, Uttar Nowabil, P.O.:- Ambari, P.S.:- Murajhar, Hojai, Assam, India, 782445
                            <br />
                            Email: issrapublisher@gmail.com
                            <br />
                            Phone: +91 6000892709
                            <br />
                            WhatsApp: +91 6000892709
                            <br />
                            <br />

                            We will endeavor to respond to your inquiries in a timely manner.
                        </p>


                    </div>

                </div>
            </Card>
        </>
    )
}

export default PrivacyPolicy;