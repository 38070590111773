import React from 'react'

import Card from '@mui/material/Card';
import { Divider } from '@mui/material';

const ResearchEthics = () => {
    return (
        <>
            <Card>
                <div className="row">
                    <div className="col-12 p-3 text-center">
                        <h3>RESEARCH ETHICS POLICY</h3>
                    </div>
                </div>
            </Card>
            <Divider />

            <Card>
                <div className="row">


                    <div className="col-md-12 p-4 px-5">
                        <p>

                            ISSRA Publishers is committed to upholding the highest standards of research integrity and ethical conduct. We strive to ensure that all research published by our organization meets rigorous ethical guidelines and promotes the responsible conduct of research. This Research Ethics Policy outlines the principles and guidelines that govern the ethical conduct of research for ISSRA Publishers.
                        </p>

                        <div>
                            <strong>

                                RESEARCH INTEGRITY AND TRANSPARENCY
                            </strong>
                        </div>

                        <div>
                            <strong>

                                Plagiarism
                            </strong>
                        </div>
                        <p>

                            ISSRA Publishers strictly prohibits plagiarism in any form. Authors are expected to properly acknowledge and cite the works of others and to ensure that their research is original and does not contain any plagiarized content.
                        </p>

                        <div>
                            <strong>

                                Data Fabrication and Falsification
                            </strong>
                        </div>
                        <p>

                            Researchers must not engage in the fabrication or falsification of data. All data and research findings must be accurately represented and reported.
                        </p>

                        <div>
                            <strong>

                                Image Manipulation
                            </strong>
                        </div>
                        <p>

                            Researchers should not engage in the manipulation, enhancement, or alteration of images in a manner that misrepresents the original data. Any image modifications should be clearly disclosed and justified.
                        </p>


                        <div>
                            <strong>

                                Conflict of Interest
                            </strong>
                        </div>
                        <p>

                            Authors should disclose any potential conflicts of interest that may influence their research findings or objectivity. This includes financial interests, affiliations, or personal relationships that could be perceived as biases.
                        </p>

                        <div>
                            <strong>

                                Research Misconduct
                            </strong>
                        </div>
                        <p>

                            ISSRA Publishers will take allegations of research misconduct seriously and will follow appropriate procedures to investigate and address any such claims.
                        </p>


                        <div>
                            <strong>

                                INFORMED CONSENT AND HUMAN SUBJECTS
                            </strong>
                        </div>

                        <div>
                            <strong>

                                Ethical Approval
                            </strong>
                        </div>
                        <p>

                            Research involving human subjects must have obtained the necessary ethical approvals from relevant institutional review boards or ethics committees. Authors should provide evidence of ethical approval or exemption in their submissions.
                        </p>

                        <div>
                            <strong>

                            Informed Consent
                            </strong>
                        </div>

                        <p>

                            Researchers must obtain informed consent from participants involved in their studies. This includes providing clear information about the research objectives, procedures, potential risks and benefits, and the right to withdraw from the study.
                        </p>


                        <div>
                            <strong>

                                Privacy and Confidentiality
                            </strong>
                        </div>
                        <p>

                            Researchers must ensure the privacy and confidentiality of human subjects. Personal information should be protected, and participants should be assured that their identities will remain confidential, unless explicit consent is obtained.
                        </p>

                        <div>
                            <strong>

                                Vulnerable Populations
                            </strong>
                        </div>

                        <p>

                            Special care must be taken when conducting research involving vulnerable populations, such as children, prisoners, or individuals with diminished capacity. Additional safeguards should be in place to protect their rights and welfare.
                        </p>

                        <div>
                            <strong>

                                ANIMAL WELFARE
                            </strong>
                        </div>

                        <div>
                            <strong>
                                Ethical Approval

                            </strong>
                        </div>

                        <p>

                            Research involving animals must comply with applicable laws, regulations, and ethical guidelines. Authors should provide evidence of appropriate ethical approval from relevant animal ethics committees.
                        </p>

                        <div>
                            <strong>

                                Animal Care and Use
                            </strong>
                        </div>

                        <p>

                            Researchers must ensure the humane treatment of animals and minimize any potential harm or distress. Animals should be housed, cared for, and used in accordance with established guidelines and regulations.
                        </p>

                        <p>

                            Adherence to this Research Ethics Policy is a fundamental requirement for all researchers, authors, reviewers, and editors involved in the publication process with ISSRA Publishers. Failure to comply with these ethical guidelines may result in appropriate actions, including the rejection of manuscripts, retraction of published articles, or the reporting of research misconduct to relevant authorities.
                        </p>


                    </div>
                </div>
            </Card>
        </>
    )
}

export default ResearchEthics;