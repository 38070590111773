import React, { useEffect, useState, useMemo, useReducer, useContext } from 'react'
// import Navbar from '../components/common/nav'
import Hero from '../components/home/hero'
import LatestArticle from '../components/home/latestArticle'
import OpenAccess from '../components/home/openAcces'
import Counter, { Counter2 } from '../components/home/counter'

import api from '../API/api'
import LoadingSkelton from '../components/common/skelton'
import LinearProgress from '@mui/material/LinearProgress';

import Upslider from '../components/home/upslider'
import Indexing from '../components/home/indexing'
import RightSide from '../components/common/rightSide'
import PublicationStep from '../components/home/publicationStep'

import Quote from '../components/other/quote'

import { JournalContext } from '../Helper/Contex'


const Homepage = () => {

    const { indexings } = useContext(JournalContext)

    const [journals, setJournals] = useState();
    const [latest, setLatest] = useState();
    const [load, setLoad] = useState(false);

    useEffect(() => {
        getJournals()
    }, []);

    const getJournals = () => {
        setLoad(true)
        api.getJournals()
            .then((res => {
                setLoad(false);
                console.log("==>", res);
                setJournals(res.data.journals);
                setLatest(res.data.latest_artricle)
            }))
            .catch((err) => {
                console.log(err.response);
            })
    }


    return (
        <>
            {load ?
                <>
                    <LinearProgress />

                    <LoadingSkelton />

                </> :
                <div>
                    <div className='container-fluid mt-2'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <Upslider />
                            </div>
                            <div className='col-md-4'>
                                <RightSide />
                            </div>
                            <div className='col-md-8'>
                                <Hero journals={journals} />
                                {/* <OpenAccess /> */}
                                {/* <LatestArticle latest={latest} /> */}
                                {/* <PublicationStep /> */}
                            </div>

                        </div>
                        <PublicationStep />
                        <Indexing indexings={indexings} />
                    </div>
                    <div className='py-2'>
                        <Counter2 />
                    </div>

                    <Quote />


                </div>
            }

        </>
    )
}

export default Homepage;