import React from 'react'

import Card from '@mui/material/Card';
import { Divider } from '@mui/material';

const EditorPolicy = () => {
    return (
        <>
            <Card>
                <div className="row">
                    <div className="col-12 p-3 text-center">
                        <h3>EDITORIAL POLICIES</h3>
                    </div>
                </div>
            </Card>
            <Divider />

            <Card>
                <div className="row">


                    <div className="col-md-12 p-4 px-5">
                        <p>

                            We are committed to maintaining the highest standards of academic integrity and publishing excellence. Our editorial policies ensure that the content we publish meets rigorous standards of quality, originality, and ethical conduct. These policies guide our editorial decision-making process and uphold the integrity and reputation of our publications. The following are the key elements of our editorial policies:
                        </p>

                        <p>

                            <strong>
                                Publication Decisions:
                            </strong>&nbsp;
                            The editor of ISSRA Publishers is responsible for deciding which of the articles submitted to the journal should be published. The validation of the work in question and its importance to researchers and readers must always drive such decisions. The editor may be guided by the policies of the journal’s editorial board and constrained by such legal requirements as shall then be in force regarding libel, copyright infringement and plagiarism. The editor may confer with other editors or reviewers in making this decision.
                        </p>


                        <p>
                            <strong>

                                Transparency:
                            </strong>&nbsp;
                            The editor should maintain the transparency of the academic research and record, preclude professional needs from cooperating with ethical standards, and always be willing to publish retractions, rectifications, and erratum when required.
                        </p>


                        <p>

                            <strong>

                                Fair play:
                            </strong>&nbsp;
                            An editor should evaluate manuscripts for their intellectual content without regard to race, gender, sexual orientation, religious belief, ethnic origin, citizenship, or political philosophy of the authors.
                        </p>


                        <p>

                            <strong>

                                Confidentiality:
                            </strong>&nbsp;
                            The editor and any editorial staff must not disclose any information about a submitted manuscript to anyone other than the corresponding author, reviewers, potential reviewers, other editorial advisers, and the publisher, as appropriate.
                        </p>

                        <p>
                            <strong>

                                Disclosure and Conflicts of Interest:
                            </strong>&nbsp;
                            Unpublished materials disclosed in a submitted manuscript must not be used in an editor’s own research without the express written consent of the author. Privileged information or ideas obtained through peer review must be kept confidential and not used for personal advantage. Editors should recuse themselves (i.e. should ask a co-editor, associate editor or other member of the editorial board instead to review and consider) from considering manuscripts in which they have conflicts of interest resulting from competitive, collaborative, or other relationships or connections with any of the authors, companies, or (possibly) institutions connected to the papers. Editors should require all contributors to disclose relevant competing interests and publish corrections if competing interests are revealed after publication. If needed, other appropriate action should be taken, such as the publication of a retraction or expression of concern.
                        </p>

                        <p>

                            <strong>

                                Involvement and Cooperation in Investigations:
                            </strong> &nbsp;
                            An editor should take reasonably responsive measures when ethical complaints have been presented concerning a submitted manuscript or published paper, in conjunction with the publisher (or society). Such measures will generally include contacting the author of the manuscript or paper and giving due consideration of the respective complaint or claims made, but may also include further communications to the relevant institutions and research bodies, and if the complaint is upheld, the publication of a correction, retraction, expression of concern, or other note, as may be relevant. Every reported act of unethical publishing behavior must be looked into, even if it is discovered years after publication.
                        </p>

                        <p>

                            Promoting research rectitude must be preserved. If at any stage the publisher suspects any kind of misconduct in research, it should be investigated promptly and in detail with suitable authority. Furthermore if any suspicious act is observed in the peer review, it should be resolved with diligence
                        </p>



                    </div>
                </div>
            </Card>
        </>
    )
}

export default EditorPolicy;