import React from 'react'
import OpenAccess from '../components/other/openAccess'
import RightSide from '../components/common/rightSide'


const OpenAccessPage = () => {
    return (
        <>
            <div className="container-fluid mt-2">
                <div className='row'>
                <div className='col-md-4'>
                        <RightSide />
                    </div>
                    <div className='col-md-8'>
                        <OpenAccess />
                    </div>
                    

                </div>
            </div>

        </>
    )
}

export default OpenAccessPage