import React from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { useNavigate } from "react-router-dom";

const Hero = (props) => {
    const navigate = useNavigate();
    return (
        <>
            <Card>
                <div className='bg-primary p-2 text-white botton-line'>
                    JOURNALS
                </div>
                <div className='m-4'>
                    <Swiper slidesPerView={4}
                        loop={false}
                        modules={[Autoplay, Scrollbar, Pagination]}
                        //autoplay={false}
                        spaceBetween={20}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        speed={800}
                        pagination={{ clickable: true }}
                    >

                        {props.journals && props.journals.map((el) => (


                            <SwiperSlide key={el.j_id}>
                                <div className="hero">
                                    <div className="card bg-dark text-white border-0">
                                        <img src={`${process.env.REACT_APP_BACKEND}assets/journal/${el.photo}`} className="card-img" alt="Banner" onClick={() => navigate('AllJournals')} />
                                    </div>

                                </div>
                            </SwiperSlide>
                        ))}

                    </Swiper>

                </div>

            </Card>


            <div className='pt-2'>
                <Card>
                    <div className='bg-primary p-2 text-white botton-line'>
                        Aim and Objective
                    </div>
                    <CardActions>
                        <div className="m-2 text-justify">
                            ISSRA Publishers is a proven, trusted, and reliable source for publishing and disseminating cutting-edge resources that fuel innovations and inspire positive societal changes. ISSRA Publishers strives to enhance the current body of scientific knowledge through close collaborations with researchers and scholars worldwide. Through a commitment to sound ethical practices, an agile publishing process, and customer-centric values, ISSRA Publishers accelerates the pace of research discoveries.
                            <br />
                            <br />
                            Our vision is to provide a viable and credible publishing experience for aspiring and prominent researchers who seek to expand the current body of scientific knowledge across diverse fields of study.
                        </div>
                    </CardActions>
                    <Divider />
                    {/* <CardActions>
                        <Button size="small">Global Journal of Engineering and Technology [GJET]</Button>
                    </CardActions>
                    <Divider />
                    <CardActions>
                        <Button size="small">Global Scientific and Academic Research Journal of Multidisciplinary Studies</Button>
                    </CardActions> */}
                </Card>
            </div>
        </>
    );
}

export default Hero;