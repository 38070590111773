import React from 'react'
import RightSide from '../components/common/rightSide'
import JoinEditor from '../components/other/joinEditor'

const JoinEditorPage=()=>{
    return (
        <>
          <div className='container-fluid mt-2'>
                <div className='row'>
                {/* <div className='col-md-4'>
                        <RightSide />
                    </div> */}
                    <div className='col-md-12'>
                        <JoinEditor />
                    </div>
                    
                </div>
            </div>

        </>
    )
}

export default JoinEditorPage