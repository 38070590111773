import React from "react";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';

import Card from '@mui/material/Card';
import { useNavigate } from "react-router-dom";
import ManuBtn from "../common/manuButton";

import { Parallax, Background } from "react-parallax";

const Upslider = (props) => {
    const navigate = useNavigate();
    return (
        <>
            <div className="mb-2">
                <Card>
                    {/* <div className='bg-primary p-2 text-white botton-line'>
                    JOURNALS
                </div> */}
                    <div className='mb-1'>
                        <Swiper slidesPerView={1}
                            loop={false}
                            modules={[Autoplay, Scrollbar, Pagination]}
                            //autoplay={false}
                            spaceBetween={20}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            speed={800}
                            pagination={{ clickable: true }}
                        >

                            {/* {props.journals && props.journals.map((el) => ( */}


                            <SwiperSlide>
                                <div className="hero">
                                    <Parallax bgImage="assets/hero/2.jpg" strength={500}>
                                        <div style={{ height: 340 }}>
                                            <div className="card-img-overlay d-flex flex-column justify-content-center">
                                                <div className="overlay"></div>
                                                <div className="container">
                                                    {/* <h5 className="card-title display-5 fw-bolder mb-0">LATEST PUBLISHED ARTICLE</h5> */}
                                                    <h3 className="text-white" style={{ fontFamily: 'none', background: '#00000080' }}>"Research is the compass that guides us through the uncharted territories of knowledge, illuminating the path to discovery." - Anonymous</h3>
                                                    <button className="btn btn-success">Submit Manuscript</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Parallax>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="hero">
                                    <Parallax bgImage="assets/hero/hero1.jpg" strength={500}>
                                        <div style={{ height: 340 }}>
                                            <div className="card-img-overlay d-flex flex-column justify-content-center">
                                                <div className="overlay"></div>
                                                <div className="container">
                                                    {/* <h5 className="card-title display-5 fw-bolder mb-0">LATEST PUBLISHED ARTICLE</h5> */}
                                                    <h3 className="text-white" style={{ fontFamily: 'none', background: '#00000080' }}>"The true beauty of research lies not only in finding answers but in asking the right questions." - Albert Einstein</h3>
                                                    <button className="btn btn-success">Submit Manuscript</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Parallax>
                                </div>
                            </SwiperSlide>

                            <SwiperSlide>
                                <div className="hero">
                                    <Parallax bgImage="assets/hero/3.jpg" strength={500}>
                                        <div style={{ height: 340 }}>
                                            <div className="card-img-overlay d-flex flex-column justify-content-center">
                                                <div className="overlay"></div>
                                                <div className="container">
                                                    {/* <h5 className="card-title display-5 fw-bolder mb-0">LATEST PUBLISHED ARTICLE</h5> */}
                                                    <h3 className="text-white" style={{ fontFamily: 'none', background: '#00000080' }}>"Research is the art of turning curiosity into knowledge, and knowledge into progress." - Marie Curie</h3>
                                                    <button className="btn btn-success">Submit Manuscript</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Parallax>
                                </div>
                            </SwiperSlide>



                            {/* <SwiperSlide>
                            <div className="hero">
                                <Parallax bgImage="assets/hero/hero1.jpg" strength={500}>
                                    <div style={{ height: 340 }}>
                                        <div className="card-img-overlay d-flex flex-column justify-content-center">
                                            <div className="overlay"></div>
                                            <div className="container">
                                                <h5 className="card-title display-5 fw-bolder mb-0">LATEST PUBLISHED ARTICLE</h5>
                                                <button className="btn btn-success">Click Here</button>
                                            </div>
                                        </div>
                                    </div>
                                </Parallax>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="hero">
                                <Parallax bgImage="assets/hero/hero1.jpg" strength={500}>
                                    <div style={{ height: 340 }}>
                                        <div className="card-img-overlay d-flex flex-column justify-content-center">
                                            <div className="overlay"></div>
                                            <div className="container">
                                                <h5 className="card-title display-5 fw-bolder mb-0">LATEST PUBLISHED ARTICLE</h5>
                                                <button className="btn btn-success">Click Here</button>
                                            </div>
                                        </div>
                                    </div>
                                </Parallax>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="hero">
                                <Parallax bgImage="assets/hero/hero1.jpg" strength={500}>
                                    <div style={{ height: 340 }}>
                                        <div className="card-img-overlay d-flex flex-column justify-content-center">
                                            <div className="overlay"></div>
                                            <div className="container">
                                                <h5 className="card-title display-5 fw-bolder mb-0">LATEST PUBLISHED ARTICLE</h5>
                                                <button className="btn btn-success">Click Here</button>
                                            </div>
                                        </div>
                                    </div>
                                </Parallax>
                            </div>
                        </SwiperSlide> */}


                            {/* <SwiperSlide>
                            <div className="hero">
                                <div className="card bg-dark text-white border-0">
                                    <img src="assets/hero/hero1.jpg" className="card-img" alt="Banner" />
                                    <div className="card-img-overlay d-flex flex-column justify-content-center">
                                        <div className="overlay"></div>
                                        <div className="container">
                                            <h5 className="card-title display-5 fw-bolder mb-0">LATEST PUBLISHED ARTICLE</h5>
                                            <button className="btn btn-success">Click Here</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="hero">
                                <div className="card bg-dark text-white border-0">
                                    <img src="assets/hero/hero1.jpg" className="card-img" alt="Banner" />
                                    <div className="card-img-overlay d-flex flex-column justify-content-center">
                                        <div className="overlay"></div>
                                        <div className="container">
                                            <h5 className="card-title display-5 fw-bolder mb-0">LATEST WINTER COLLECTIONS</h5>
                                            <p className="card-text lead  my-3">CHECK OUT ALL OUR LATEST TRENDS</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide> */}
                            {/* ))} */}

                        </Swiper>

                    </div>

                </Card>
            </div>
        </>
    );
}

export default Upslider;