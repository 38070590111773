import React, { useState, useEffect } from 'react'
import Card from '@mui/material/Card';

import PersonIcon from '@mui/icons-material/Person';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import SellIcon from '@mui/icons-material/Sell';
import Button from '@mui/material/Button';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import Collapse from 'react-bootstrap/Collapse';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';


const LatestArticle = (props) => {

    const [objectsList, setObjectsList] = React.useState([])

    const handleClick = (item) => {
        console.log(item.id);
        setObjectsList(
            objectsList.map(e => e.id === item.id ? ({ ...e, isOpen: !e.isOpen }) : (e))
        )
    };

    useEffect(() => {
        if (props.latest) {
            setObjectsList(
                props.latest.map(e => ({ ...e, isOpen: false }))
            );
        }

    }, [props.latest])

    return (
        <>
            <div className='pt-2'>
                <Card>
                    <div className='bg-primary p-2 text-white botton-line'>
                        Latest Articles
                    </div>
                    <div>
                        {objectsList && objectsList.map((el) => (


                            <div className='card m-2'>
                                <div className=' m-2'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <button className='btn btn-success btn-sm'>Original Article</button>
                                        </div>
                                        <div className='col-md-6 text-end'>
                                            <span className='text-secondary'>{
                                                // new Date(el.created_at).getDate() + ', ' +
                                                // new Date(el.created_at).toLocaleString("default", {
                                                //     month: "long",
                                                // }) +
                                                // "-" +
                                                // new Date(el.created_at).getFullYear()


                                            }</span>
                                        </div>
                                    </div>

                                    <div className='pt-3'>

                                        <div className='col-12'>
                                            <strong>{el.name}</strong>
                                        </div>
                                        <div className='col-12'>
                                            <PersonIcon fontSize='small' /> {el.aname}
                                        </div>
                                        {el.designation &&
                                            <div className='col-12'>
                                                <BookmarksIcon fontSize='small' /> {el.designation}
                                            </div>
                                        }

                                        {el.doi &&
                                            <div className='col-12'>
                                                <SellIcon fontSize='small' /> DOI : {el.doi}
                                            </div>
                                        }
                                        {el.page &&
                                            <div className='col-12'>
                                                <AutoStoriesIcon fontSize='small' /> Page No : {el.page}
                                            </div>
                                        }

                                        <div className='m-4'>
                                            <button className='btn btn-secondary btn-sm' onClick={() => handleClick(el)}
                                                aria-controls="example-collapse-text"
                                                aria-expanded={el.isOpen}>
                                                <DocumentScannerIcon fontSize='small' /> Abstract
                                            </button>
                                            &nbsp;
                                            <a href={`${process.env.REACT_APP_BACKEND}assets/articles/${el.file}`} target="_blank" className='btn btn-secondary btn-sm'>
                                                <PictureAsPdfIcon fontSize='small' /> PDF
                                            </a>
                                            &nbsp;
                                            {/* <a href={`${process.env.REACT_APP_BACKEND}assets/articles/${el.file}`} className='btn btn-secondary btn-sm'>
                                                <TextSnippetIcon fontSize='small' /> Full-Text
                                            </a>
                                            &nbsp; */}
                                            {/* <a href={el.googleScholar} target='_blank' className='btn btn-success btn-sm'>
                                                Google scholar
                                            </a> */}

                                            <Card>
                                                <Collapse in={el.isOpen}>
                                                    <div id="example-collapse-text" className='m-2'>
                                                        {el.abstract}
                                                    </div>
                                                </Collapse>
                                            </Card>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}


                    </div>
                </Card>
            </div>
        </>
    )
}

export default LatestArticle