import React from 'react'
import Card from '@mui/material/Card';

import { Divider } from '@mui/material';

const AuthorResponsibility = () => {
    return (
        <>
            <Card>
                <div className='p-3 text-center'>
                    <h3>  AUTHORS' RESPONSIBILITY </h3>
                </div>
            </Card>
            <Divider />
            <Card>
                <div className="row">

                    <div className='col-md-12 p-4 m-2 px-5'>


                        <p>

                            As an author publishing with ISSRA Publishers, you have certain responsibilities towards the publisher to ensure a successful collaboration and the production of high-quality work. These responsibilities include:
                        </p>

                        <div>
                            <strong>

                                MANUSCRIPT ORIGINALITY
                            </strong>
                        </div>
                        <p>

                            As an author, it is your responsibility to submit original and authentic work. Plagiarism, the use of someone else's work without proper attribution, is strictly prohibited. You should ensure that your manuscript does not infringe upon any copyright laws and that all sources are appropriately cited.
                        </p>

                        <div>
                            <strong>

                                MANUSCRIPT PREPARATION
                            </strong>
                        </div>
                        <p>

                            You are responsible for preparing your manuscript according to the guidelines provided by ISSRA Publishers. This includes adhering to the formatting requirements, word limits, and citation styles specified by the publisher. By following these guidelines, you help streamline the publishing process and ensure consistency across the publisher's catalog.
                        </p>

                        <div>
                            <strong>

                                CONTENT ACCURACY AND VALIDITY
                            </strong>
                        </div>
                        <p>

                            It is your responsibility to ensure the accuracy and validity of the content presented in your manuscript. This involves conducting thorough research, verifying facts, and citing reliable sources. By maintaining high standards of academic integrity, you contribute to the credibility of your work and the reputation of ISSRA Publishers.
                        </p>

                        <div>
                            <strong>

                                TIMELY SUBMISSION
                            </strong>

                        </div>

                        <p>

                            Meeting deadlines is crucial for a smooth publishing process. You should submit your manuscript within the agreed-upon timeframe or according to the publisher's submission guidelines. Timely submission allows for efficient editing, peer review, and production processes, ensuring that your work reaches its intended audience in a timely manner.
                        </p>

                        <div>
                            <strong>

                            </strong>
                            COLLABORATION AND COMMUNICATION
                        </div>

                        <p>

                            Maintaining effective communication with the publisher is essential. You should promptly respond to any queries or requests for revisions from the publisher or the editorial team. Additionally, collaboration may involve working closely with the editor, providing necessary revisions, and addressing reviewer comments constructively.
                        </p>

                        <div>
                            <strong>

                                ETHICAL CONSIDERATIONS
                            </strong>
                        </div>

                        <p>

                            Authors should adhere to ethical standards throughout the publishing process. This includes obtaining necessary permissions for the use of copyrighted material, disclosing any potential conflicts of interest, and ensuring that research involving human subjects or animals follows appropriate ethical guidelines.
                        </p>


                        <div>
                            <strong>

                            </strong>
                            PROMOTION AND DISSEMINATION
                        </div>
                        <p>

                            While ISSRA Publishers will have its own marketing and promotional strategies, you can contribute to the dissemination of your work by actively participating in promotional activities. This may involve sharing your published work on your personal or professional networks, attending conferences, and engaging with the academic community.
                        </p>

                        <p>

                            Remember, the responsibilities listed above are general guidelines, and you should consult the specific guidelines provided by ISSRA Publishers for detailed instructions on manuscript submission, preparation, and ethical considerations. By fulfilling your responsibilities as an author, you contribute to a productive and mutually beneficial partnership with ISSRA Publishers, ensuring the successful publication of your work.
                        </p>


                    </div>
                </div>
            </Card>
            <br />
        </>
    )
}

export default AuthorResponsibility