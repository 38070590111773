import React from 'react'

import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';

const PublicationStep = () => {
    return (
        <>
            <div className='pt-2'>
                <Card>
                    <div className='bg-primary p-2 text-white botton-line'>
                        Manuscript Publication Step
                    </div>

                    <div className="input-group p-3 text-center">
                        <div className="form-outline w-100">
                            <img src='assets/ps.png' className='col-12'/>
                        </div>
                    </div>

                </Card>
            </div>
        </>
    )
}

export default PublicationStep;