import React from 'react'
import JournalDetails from '../components/journals/journalDetails';
import RightSide from '../components/common/rightSide';

const JournalDetailsPage = () => {
    return (
        <>

            <div className='container-fluid mt-2'>
                <div className='row'>
                    {/* <div className='col-md-4'>
                        <RightSide />
                    </div> */}
                    <div className='col-md-12'>
                        <JournalDetails />
                    </div>

                </div>
            </div>


        </>
    )
}

export default JournalDetailsPage;