import React from 'react'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import TwitterIcon from '@mui/icons-material/Twitter';

const LogoHeader = () => {
    return (
        <>

            <div className='m-0 p-2  bg-head '
            //   style={{ backgroundImage: 'url(/assets/logobg.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }}
            >
                <div className='mx-1'>
                    <div className=" ">


                        <div className="row align-items-center justify-content-center">
                            <div className="col-2 d-flex justify-content-end">
                                <img src='/assets/mainlogo.png' className='col-8 white-shadow' alt='Logo' />
                            </div>
                            <div className='col-5'>
                                <h2 className='head-name black-shadow'> <b> ISSRA Publishers </b> </h2>
                                <h4 className='head-full-name black-shadow'>International Scholars Society of Research Activities</h4>
                                <div className='moto black-shadow text-center'>
                                  <i>  (A community with high expectation and high academic achievement) </i>
                                </div>
                               
                            </div>
                        </div>






                        {/* <div className="row align-items-center ">
                            <div className="col-2 d-flex justify-content-center">
                                <img src='/assets/mainlogo.png' className='col-10 white-shadow' alt='Logo' />
                            </div>

                            <div className="col-8">
                                <div className='pl-2'>
                                    <h2 className='head-name black-shadow'> <b> ISSRA Publishers </b> </h2>
                                    <h4 className='head-full-name black-shadow'>International Scholars Society of Research Activities</h4>
                                    <div className='moto black-shadow'>
                                        (A community with high expectation and high academic achievement)
                                    </div>
                                </div>
                            </div>

                            <div className="col-2">
                                <FacebookIcon fontSize='large' sx={{ color: 'white' }} />
                                <InstagramIcon fontSize='large' sx={{ color: 'white' }} />
                                <TwitterIcon fontSize='large' sx={{ color: 'white' }} />
                            </div>
                        </div> */}





                    </div>
                </div>
            </div>

        </>
    )
}

export default LogoHeader;