import React from 'react'
import Terms from '../components/other/terms';
import RightSide from '../components/common/rightSide';

const TermsPage = () => {
    return (
        <>
            <div className="container-fluid mt-2">
                <div className='row'>
                {/* <div className='col-md-4'>
                        <RightSide />
                    </div> */}
                    <div className='col-md-12'>
                        <Terms />
                    </div>
                   
                </div>
            </div>

        </>
    )
}

export default TermsPage;