import React from 'react'
import Card from '@mui/material/Card';
import { Divider } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { useNavigate } from "react-router-dom";

const ReviewerPolicy = () => {
    const navigate = useNavigate();
    return (
        <>
            <Card>
                <div className="row">
                    <div className="col-12 p-4 text-center">
                        <h3>REVIEWER POLICY FOR ISSRA PUBLISHERS</h3>
                    </div>
                </div>
            </Card>
            <Divider />

            <Card>
                <div className="row">


                    <div className="col-md-12 p-4 px-5">

                        <p>


                            ISSRA Publishers is committed to maintaining the highest standards of academic publishing and ensuring the quality and integrity of the manuscripts we publish. To achieve this, we have established the following reviewer policy to guide our interactions with reviewers and maintain a rigorous peer review process.
                        </p>

                        <div>
                            <strong>

                                SELECTION OF REVIEWERS:
                            </strong>
                        </div>
                        <p>

                            •	We select reviewers based on their expertise in the subject area of the manuscript.
                            <br />
                            •	Reviewers are chosen from a diverse pool of researchers, including both established academics and emerging scholars.
                            <br />
                            •	We ensure that reviewers have no conflicts of interest with the authors or the content of the manuscript.
                            <br />
                            •	We strive to maintain a balance in terms of gender, nationality, and institutional affiliation among our pool of reviewers.
                            <br />
                        </p>


                        <div>
                            <strong>
                                REVIEWER RESPONSIBILITIES:

                            </strong>
                        </div>

                        <p>

                            •	Reviewers are expected to review assigned manuscripts objectively, impartially, and in a timely manner.
                            <br />
                            •	Reviewers should maintain the confidentiality of the review process and not disclose any information about the manuscript or their review to any unauthorized individuals.
                            <br />
                            •	Reviewers should provide constructive feedback to help authors improve their manuscripts and meet the standards of ISSRA Publishers.
                            <br />
                            •	Reviewers should promptly notify the editor if they are unable to review a manuscript within the agreed timeline or if they identify any conflicts of interest during the review process.
                            <br />
                        </p>


                        <div>
                            <strong>

                                REVIEW PROCESS:
                            </strong>
                        </div>

                        <p>

                            •	Reviewers are provided with clear guidelines and evaluation criteria to assess the quality, originality, and relevance of the manuscript.
                            <br />
                            •	Reviewers should assess the methodology, research design, data analysis, and interpretation, ensuring they adhere to ethical standards.
                            <br />
                            •	Reviewers should highlight any potential ethical concerns, such as plagiarism, data fabrication, or undisclosed conflicts of interest, to the editor.
                            <br />
                            •	Reviewers should provide a comprehensive and constructive review report, including strengths, weaknesses, and suggestions for improvement.
                            <br />
                            •	Reviewers may recommend acceptance, revision, or rejection of the manuscript, supported by clear justifications for their decision.
                            <br />
                        </p>



                        <div>
                            <strong>

                                REVIEWER RECOGNITION:
                            </strong>
                        </div>
                        <p>

                            •	ISSRA Publishers acknowledges and appreciates the valuable contributions of reviewers.
                            <br />
                            •	Reviewers' names are kept confidential unless they opt for open review, in which case their names may be disclosed to the authors.
                            <br />
                            •	Reviewers may request a certificate of recognition for their review contributions upon completion of a specified number of reviews.
                            <br />
                        </p>

                        <div>
                            <strong>

                                REVIEWER FEEDBACK AND TRAINING:
                            </strong>
                        </div>

                        <p>

                            •	ISSRA Publishers welcomes feedback from reviewers on the peer review process and continuously strives to improve the quality and efficiency of the review process.
                            <br />
                            •	We provide opportunities for reviewers to participate in workshops, webinars, or training sessions to enhance their reviewing skills and stay updated on the latest trends in their respective fields.
                            <br />
                        </p>

                        <p>

                            ISSRA Publishers is committed to upholding the principles of academic integrity and fostering a collaborative relationship between reviewers, authors, and editors. We believe that maintaining a robust reviewer policy contributes to the overall quality and credibility of the manuscripts we publish.
                        </p>


                    </div>
                </div>
            </Card>
        </>
    )
}

export default ReviewerPolicy;