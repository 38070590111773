import React, { useContext, useEffect, useState } from 'react'
import BasicCard from '../home/card';
import TopEditors from '../home/topeditors';
import TractArticle from '../home/trackArticle';
import LoadingSkelton from './skelton';
import LinearProgress from '@mui/material/LinearProgress';
import OpenAccess from '../home/openAcces';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { Paper } from '@mui/material';

import { JournalContext } from '../../Helper/Contex';

const RightSide = () => {
    const navigate = useNavigate();

    const { journals, indexings, topEditors } = useContext(JournalContext)



    const [load, setLoad] = useState(false);



    return (
        <>
            {load ?
                <>
                    <LinearProgress />

                    <LoadingSkelton />

                </> :
                <>

                    <Paper>
                        <div className='p-2'>

                            <div className='pb-2'>
                                <Button variant='contained' onClick={()=>navigate('/EditorPolicy')} fullWidth>Editorial Policies</Button>
                            </div>
                            <div className='pb-2'>
                                <Button variant='contained' onClick={()=>navigate('/PublicationEthics')} fullWidth>Publication Ethics Policy</Button>
                            </div>
                            <div className='pb-2'>
                                <Button variant='contained'  onClick={()=>navigate('/ResearchEthics')} fullWidth>Research Ethics Policy</Button>
                            </div>
                            <div className='pb-2'>
                                <Button variant='contained' onClick={()=>navigate('/ReviewerPolicy')}  fullWidth>Reviewer Policy</Button>
                            </div>

                        </div>
                    </Paper>


                    {/* <BasicCard journals={journals} indexings={indexings} /> */}


                    <TopEditors topEditors={topEditors} />
                    {/* <TractArticle /> */}
                    <OpenAccess />
                </>
            }

        </>
    )
}

export default RightSide;