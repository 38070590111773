import React, { useEffect, useState } from 'react'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import { useNavigate, Outlet } from 'react-router-dom';
import { Paper } from '@mui/material';

import api from '../../API/api';

import LoadingSkelton from '../common/skelton';
import LinearProgress from '@mui/material/LinearProgress';



const AllJournals = () => {
    const navigator = useNavigate();
    const [journals, setJournals] = useState([]);

    const [load, setLoad] = useState(false);

    useEffect(() => {
        getJournals();
    }, []);



    const getJournals = () => {
        setLoad(true)

        api.getJournals()
            .then((res) => {
                console.log(res.data);
                setJournals(res.data.journals);
                setLoad(false);
            })
            .catch((err) => {
                console.log(err.response);
                setLoad(false);
            })

    }




    return (
        <>
            {load ?
                <>
                    <LinearProgress />

                    <LoadingSkelton />

                </> :
                <div className="container">
                    <div className="row">

                        {journals.map((journal) => (
                            <div className="col-md-6 p-2">
                                <Paper elevation={6}>
                                    <div className="row">
                                        <div className="col-4 d-flex flex-column justify-content-center">
                                            <img src={`${process.env.REACT_APP_BACKEND}assets/journal/${journal.photo}`} className="col-10 p-2" alt="Banner" />
                                        </div>

                                        <div className="col-8 d-flex flex-column justify-content-center">
                                            <div className='m-1 p-2'>

                                                <span className='text-dark '> <strong>{journal.j_name}</strong> </span>

                                                <div className='font-small'> <strong> Abbr. Title:</strong> <i> {journal.abbr_title} </i></div>
                                                <div className='font-small'> <strong> ISSN(Online):</strong>  {journal.issn} </div>
                                                <div className='font-small'> <strong> Publisher:</strong> {journal.publisher}  </div>
                                                <div className='font-small'> <strong> Language: </strong> {journal.language}  </div>
                                                <div className='text-right pt-2 '>
                                                    <button className='btn btn-info btn-sm ' onClick={() => navigator(`/journal/${journal.slug}`)}>Read More</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </Paper>
                            </div>

                        ))

                        }


                    </div>
                </div>
            }


        </>
    )
}

export default AllJournals;