import React from 'react'
import AuthorResponsibility from '../components/other/authorsResponsibility';
import RightSide from '../components/common/rightSide';

const AuthorResponsibilityPage = () => {
    return (
        <>
            <div className="container-fluid mt-2">
                <div className='row'>
                {/* <div className='col-md-4'>
                        <RightSide />
                    </div> */}
                    <div className='col-md-12'>
                        <AuthorResponsibility />
                    </div>
                   
                </div>
            </div>

        </>
    )
}

export default AuthorResponsibilityPage;