import React, { useEffect } from 'react'
import Card from '@mui/material/Card';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import 'swiper/css/autoplay';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const TopEditors = (props) => {

    useEffect(() => {
        console.log(props.topEditors);
    })
    return (
        <>
            {props.topEditors &&
                <div className='pt-2'>
                    <Card>
                        <div className='bg-primary p-2 text-white botton-line'>
                            TOP EDITORS
                        </div>

                        <div className='m-4'>
                            <Swiper
                                slidesPerView={2}
                                loop={true}
                                modules={[Autoplay, Scrollbar, Pagination]}
                                //autoplay={false}
                                spaceBetween={20}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                // navigation
                                speed={800}
                                pagination={{ clickable: true }}
                            >

                                {props.topEditors.map((editor) => (
                                    <SwiperSlide>
                                        <div className="hero">
                                            <div className="card bg-dark text-white border-0">
                                                <img src={`${process.env.REACT_APP_BACKEND}assets/editors/${editor.image}`} className="card-img" alt="Banner" height="150" />

                                            </div>

                                        </div>
                                    </SwiperSlide>

                                ))}


{/* 
                                <SwiperSlide>
                                    <div className="hero">
                                        <div className="card bg-dark text-white border-0">
                                            <img src="/assets/editors/2.jpg" className="card-img" alt="Banner" height="150" />

                                        </div>

                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="hero">
                                        <div className="card bg-dark text-white border-0">
                                            <img src="/assets/editors/3.jpg" className="card-img" alt="Banner" height="150" />

                                        </div>

                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="hero">
                                        <div className="card bg-dark text-white border-0">
                                            <img src="/assets/editors/4.jpg" className="card-img" alt="Banner" height="150" />

                                        </div>

                                    </div>
                                </SwiperSlide> */}

                            </Swiper>
                        </div>
                    </Card>
                </div>
            }
        </>
    )
}


export default TopEditors;