import React from 'react'
import RightSide from '../components/common/rightSide';
import ReviewerPolicy from '../components/other/reviewerPolicy';


const ReviewerPolicyPage = () => {
    return (
        <>
            <div className='container-fluid mt-2'>
                <div className='row'>
                    {/* <div className='col-md-4'>
                        <RightSide />
                    </div> */}
                    <div className='col-md-12'>
                        <ReviewerPolicy />
                    </div>

                </div>
            </div>
        </>
    )
}

export default ReviewerPolicyPage;