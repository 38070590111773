import React from 'react'
import AllJournals from '../components/journals/alljournals'
import RightSide from '../components/common/rightSide'

const AllJournalsPage = () => {
    return (
        <>
            <div className="container-fluid mt-2">
                <div className="row">
                    {/* <div className="col-md-4">
                        <RightSide />
                    </div> */}
                    <div className="col-md-12">
                        <AllJournals />
                    </div>
                    
                </div>

            </div>
        </>
    )
}

export default AllJournalsPage