import React from 'react'
import RightSide from '../components/common/rightSide';
import JoinAuthor from '../components/other/joinAuthor';


const JoinAuthorPage = () => {
    return (
        <>
            <div className='container-fluid mt-2'>
                <div className='row'>
                {/* <div className='col-md-4'>
                        <RightSide />
                    </div> */}
                    <div className='col-md-12'>
                    <JoinAuthor />
                    </div>
                    
                </div>
            </div>
        </>
    )
}

export default JoinAuthorPage;