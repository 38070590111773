import React from 'react'
import RightSide from '../components/common/rightSide';
import ContactUs from '../components/other/contactUs';



const ContactUsPage = () => {
    return (
        <>
            <div className='container-fluid mt-2'>
                <div className='row'>
                    {/* <div className='col-md-4'>
                        <RightSide />
                    </div> */}
                    <div className='col-md-12'>
                        <ContactUs />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUsPage;